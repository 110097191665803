import React, { Component } from "react";
import { FormField } from "react-form";

import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core";  
import HelpLabel from "./HelpLabel";

const styles = (theme) => ({
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%",
        border: "1px solid #979797",
        padding: "8px 0px 8px 0px",
        borderRadius: 4,
        marginRight: -8,
    },
    opacity: {
        //opacity: 0.8,
        [theme.breakpoints.up("xl")]: {
            width: "33% !important",
        },
        [theme.breakpoints.down("lg")]: {
            width: "49% !important",
        },
    },
    wtfwidth: {
        //opacity: 0.8,
        width: "400px !important",
    },
    wtfwidth2: {
        //opacity: 0.8,
        width: "550px !important",
    },
    wtfwidth3: {
        //opacity: 0.8,
        // width: "1100px !important",
        overflowWrap:'break-word',
        whiteSpace: 'pre-wrap'
    },
    groupName: {
        fontWeight: "bold",
        marginTop: 24,
        color: "rgba(0, 0, 0, 0.74) !important",
    },
});

function ArrayFormField(FormComponent) {
    const ConnectedFormField = FormField(FormComponent);
    class ConnectedArrayFormField extends ConnectedFormField {
        shouldComponentUpdate(nextProps, nextState, nextContext) {
            const field = this.props.field,
                currentApi = this.context.formApi,
                nextApi = nextContext.formApi,
                currentValue = currentApi.values[field],
                nextValue = nextApi.values[field];
            if (currentValue && nextValue && currentValue.join(":") === nextValue.join(":")) {
                // Copy exact array reference so FormField's equality comparsion works
                nextApi.values[field] = currentApi.values[field];
            }
            return super.shouldComponentUpdate(nextProps, nextState, nextContext);
        }
    }
    return ConnectedArrayFormField;
}

class CheckboxGroupWrapper extends Component {
    state = {
        disabled: [],
    };
    componentDidMount() {
        const _this = this;
        setTimeout(function () {
            _this.handleChange();
        }, 100);
    }

    handleChange = (event) => {
        const { fieldApi, eventHandle, onlyAllowOne } = this.props;
        const { getValue, setValue, getFieldName } = fieldApi;

        let value = [...(getValue() || [])];

        if (event) {
            if (event.target.checked){
                if(onlyAllowOne){//only allow one to be checked at a time
                   value = [event.target.value] 
                }else{//multicheck case
                    value.push(event.target.value)
                } 
            } 
            else {
                if(onlyAllowOne){//clear only one selected case
                    value = []
                }else{//multiple selection case
                    var index = value.indexOf(event.target.value);
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                }
            }
            setValue(value);
        }

        if (eventHandle) eventHandle(value, getFieldName(), this);
    };

    render() {
        const {
            fieldApi,
            label,
            children,
            options,
            classes,
            required,
            helperText,
            topHelperText,
            fullWidth,
            row,
            width,
            groups,
            wtfwidth,
            wtfwidth2,
            wtfwidth3,
            disabled, 
            ...rest
        } = this.props;

        const { getValue, getError } = fieldApi;

        const value = getValue() || [],
            error = getError();

        return (
            <>
                {children && <FormHelperText>{children}</FormHelperText>}
                <FormControl component="fieldset" required={required} error={error} fullWidth={fullWidth} className={classes.formControl}>
                    <FormLabel style={{ color: "rgba(0, 0, 0, 0.74)" }} component="legend">
                        {label}
                    </FormLabel>
                    {!groups && (
                        <FormGroup row={row} style={{ marginLeft: 8 }}>
                            <>
                            {topHelperText && <FormHelperText style={{fontSize:'.85rem'}}>{topHelperText}</FormHelperText>}
                            {options.map((object) => (
                                <>
                                <FormControlLabel
                                    key={object.value}
                                    style={{ width: "400px !important", marginRight: 14 }}
                                    className={wtfwidth ? classes.wtfwidth : wtfwidth2 ? classes.wtfwidth2 : wtfwidth3 ? classes.wtfwidth3 : classes.opacity}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={value.includes(object.value)}
                                            onChange={this.handleChange}
                                            value={object.value}
                                            disabled={disabled ? disabled : this.state.disabled[object.value]}
                                            {...rest}
                                        />
                                    }
                                    label={  
                                        <>
                                            {`${object.label} `}
                                            {object && object.linkhref && ( 
                                                <a
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    style={{ textDecoration: "underline", paddingRight: 10, marginTop: -29 }}
                                                    href={object.linkhref}>
                                                    {object.linkname}
                                                </a>  
                                            )}
                                            {object && object.help && (
                                                <div style={{marginLeft:10, display:"inline-block"}}>
                                                    <HelpLabel 
                                                    inputLabel=""
                                                    helpText={`${object.help}`}
                                             /></div>
                                            )}
                                        </> 
                                  }
                                />  
                                </> 
                            ))}  
                            </>
                        </FormGroup>
                    )}
                    {required && <FormHelperText>Required</FormHelperText>}
                    {groups &&
                        options.map((group, i) => (
                            <div>
                                <FormLabel component="legend" className={classes.groupName}>
                                    {group.label}
                                </FormLabel>
                                <FormGroup row={row} style={{ marginLeft: 8 }}>
                                    {group.children.map((object) => (
                                        <FormControlLabel
                                            key={group.label + " - " + object}
                                            style={{ width: width, marginRight: 14 }}
                                            className={classes.opacity}
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={value.includes(group.label + " - " + object)}
                                                    onChange={this.handleChange}
                                                    value={group.label + " - " + object}
                                                    disabled={this.state.disabled[group.label + " - " + object]}
                                                    {...rest}
                                                />
                                            }
                                            label={object}
                                        />
                                    ))}
                                </FormGroup>
                                {i !== options.length - 1 && <Divider style={{ marginTop: 16 }} />}
                            </div>
                        ))}
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            </>
        );
    }
}
CheckboxGroupWrapper.displayName = "CheckboxGroupWrapper";
const FppCheckboxGroup = ArrayFormField(CheckboxGroupWrapper);

export default withStyles(styles)(FppCheckboxGroup);
