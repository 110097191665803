//REACTSELECT
import React from "react";
import Select from "react-select";
import { FormField } from "react-form";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
//styles for select component.
const colourStyles = {
    control: (styles, state) => ({
        //1st param is default styles, to modify use 2nd prop 'state' and modify
        // refer to https://react-select.com/styles#overriding-the-theme
        ...styles,
        backgroundColor: state.isDisabled ? "#EEEEEE" : "white",
        margin: "-1.5px",
        borderColor: state.isFocused ? "lightgreen" : "gray",
        "&:hover": { borderColor: state.isFocused ? "lightgreen" : "gray" },
        font: "15px",
        color: "#262626",
        boxShadow: "none",
        //height: 32,
        minHeight: state.selectProps.size,
    }),

    singleValue: (styles, state) => ({
        ...styles,
        fontSize: "15px",
        fontWeight: 500,
        color: "#262626 !important",
        fontFamily: "'Source Sans Pro', sans-serif",
    }),
    //dropdown to have it not underlap when open--specifically useful with repeatable dialog
    menuPortal: (base) => ({ ...base, zIndex: 10000000 }),
    //dropdown
    container: (styles, state) => ({
        ...styles,
        borderRadius: "4px",
        //marginTop: 4,
        borderColor: state.isFocused && "#a5db7a",
        boxShadow: state.isFocused && "0 0 0 0.2rem rgba(0,123,255,.25)",
        zIndex: state.isFocused && 10000000,
        width: "calc(100% - 8px)",
    }),
    //for overriding optgroups styling of header
    groupHeading: () => ({
        color: "#515151",
        backgroundColor: "#d3d3d3",
        paddingLeft: "1%",
        paddingTop: 8,
        height: 30,
        fontWeight: 500,
    }),
    group: () => ({
        //each group styling, no passing of params as we override everything
        paddingTop: 0,
        paddingBottom: 0,
    }),
    indicatorsContainer: (styles, state) => ({
        ...styles,
        height: 32,
    }),
    MenuList: () => ({
        paddingTop: 0,
    }),
    multiValue: (styles, state) => ({
        ...styles,
        backgroundColor: state.isDisabled ? null : "#EEEEEE"
    }),
    multiValueLabel: (styles, state) => ({
        ...styles,
        color: "#262626",
        fontSize: 15,
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 400,
        lineHeight: 1.5
    }),
    multiValueRemove: (styles, state) => ({
        ...styles,
        display: state.isDisabled ? "none" : "flex",
        cursor: state.isDisabled ? null : "pointer",
    }),
    clearIndicator: (styles, state) => ({
        ...styles,
        cursor: state.isDisabled ? null : "pointer",
    }),
    dropdownIndicator: (styles, state) => ({
        ...styles,
        cursor: state.isDisabled ? null : "pointer",
    }),
};
const colourStylesError = {
    control: (styles, state) => ({
        //1st param is default styles, to modify use 2nd prop 'state' and modify
        // refer to https://react-select.com/styles#overriding-the-theme
        ...styles,
        backgroundColor: state.isDisabled ? "#EEEEEE" : "white",
        margin: "-1.5px",
        borderColor: state.isFocused ? "lightgreen" : "#f44336",
        "&:hover": { borderColor: state.isFocused ? "lightgreen" : "#f44336" },
        font: "15px",
        color: "#262626",
        boxShadow: "none",
        //height: 32,
        minHeight: 32,
    }),

    container: (styles, state) => ({
        ...styles,
        borderRadius: "4px",
        //marginTop: 4,
        borderColor: state.isFocused && "#a5db7a",
        boxShadow: state.isFocused && "0 0 0 0.2rem rgba(0,123,255,.25)",
        width: "calc(100% - 8px)",
    }),
    multiValue: (styles, state) => ({
        ...styles,
        backgroundColor: state.isDisabled ? null : "#EEEEEE"
    }),
    multiValueLabel: (styles, state) => ({
        ...styles,
        color: "#262626",
        fontSize: 15,
        fontFamily: "'Source Sans Pro', sans-serif",
        fontWeight: 400,
        lineHeight: 1.5
    }),
    multiValueRemove: (styles, state) => ({
        ...styles,
        display: state.isDisabled ? "none" : "flex",
        cursor: state.isDisabled ? null : "pointer",
    }),
    clearIndicator: (styles, state) => ({
        ...styles,
        cursor: state.isDisabled ? null : "pointer",
    }),
    dropdownIndicator: (styles, state) => ({
        ...styles,
        cursor: state.isDisabled ? null : "pointer",
    }),
};

class SelectWrapper extends React.Component {
    handleChange = (event) => {
        const { fieldApi, eventHandle, quickSearchHandle } = this.props;
        const { setValue, setTouched } = fieldApi;

        setTouched();
        if (!event || event.length === 0) {
            //removal case for 'drop down' & 'multiselect' case
            setValue(" ");
            if (eventHandle) eventHandle(" ");
            return;
        }
        //multicase save--sets 1 or more selections
        if (event[0]) {
            let temp = "";
            event.forEach((selection, index) => {
                //add slashes to the dollar value into db as its > 999
                //theres a BUG on UI where it will show numerical values in dropdown if its deleted and readded this is due to the formAPI having a slash, however, saves and rerenders properly
                // if (typeof selection["value"] === "string" && !selection["value"].includes("\\,")){
                //     temp += selection["value"].replace(",", "\\,");
                // }else{
                //     temp += selection.value;
                // }
                if (typeof selection["value"] === "string" && selection["value"].includes("$") && !selection["value"].includes("\\,"))
                    selection["value"] = selection["value"].replace(",", "\\,");
                temp += selection.value;
                if (index !== event.length - 1) temp += ","; //add comma after each selection
            });
            /*return */
            if (quickSearchHandle) {
                // send back all field & contact ids
                setValue(event);
                eventHandle(event);
            } else {
                setValue(temp);
                if (eventHandle) eventHandle(temp);
            }
        } else if (event.type) {
            // adv search case only has type
            setValue(event.value);
            if (eventHandle) eventHandle(event);
        } else if (event.value || event.type) {
            //add slashes to the dollar value into db as its > 999
            if (typeof event["value"] === "string" && event["value"].includes("$") && !event["value"].includes("\\,"))
                event["value"] = event["value"].replaceAll(",", "\\,");

            //dropdown single case save
            setValue(event.value);
            if (event.contactfield) eventHandle(event);
            else if (eventHandle) eventHandle(event.value);
        }
    };

    render() {
        const {
            fieldApi,
            classes,
            label,
            options,
            section,
            formSelection,
            repeatable,
            quickSearchHandle,
            dashboardHeight,
            valuesCsv,
            primaryMirror,
            type,
            display_label,
            ...rest
        } = this.props;
        const { getError } = fieldApi;
        var error = getError(),
            hasError = !!error,
            selected = [];

        var value = fieldApi.getValue(); //prev saved values
        if (typeof value === "string") {
            if (valuesCsv && !primaryMirror) {
                selected = [...valuesCsv];
                //we remove indexs that dont match values_csv, used for multiselect with comma thousands values
                let wtf = "";
                let shouldPass = false;
                var tempArr = [];
                //we need to split out the value into an array as could have \, values (ex. $10\,000)
                for (let i = 0; i < value.length; i++) {
                    if (value[i].includes("\\")) {
                        wtf = wtf + "\\";
                        shouldPass = true;
                    } else if (shouldPass) {
                        //record this comma as \ before it
                        wtf = wtf + value[i];
                        shouldPass = false;
                    } else if (value[i] === ",") {
                        //not \ before it so record selection
                        tempArr.push(wtf);
                        wtf = "";
                    } else {
                        wtf = wtf + value[i];
                        if (i === value.length - 1) {
                            //last selection save
                            tempArr.push(wtf);
                        }
                    }
                }
                //match up with what was saved as option list and only keep valid selected
                valuesCsv.forEach((v, i) => (tempArr.find((e) => e === v) ? null : delete selected[i]));
                if (selected.length > 0) {
                    //remove slashs for UI view
                    var removeSlashes = [];
                    selected.forEach((h) => (h.includes("\\") ? removeSlashes.push(h.replaceAll("\\", "")) : removeSlashes.push(h)));
                    selected = [...removeSlashes];
                }
                options.forEach((o, i) => {
                    //options is opened dropdown remove slashes from customers view
                    return o.label.includes("\\")
                        ? ((options[i]["label"] = options[i]["label"].replaceAll("\\", "")),
                          (options[i]["value"] = options[i]["value"].replaceAll("\\", "")))
                        : null;
                });
                // }
            } else {
                //convert to array used for adv search, primary mirror as value wont be in list, etc.
                selected = value.split(",");
            }
        }

        //logic for setting the output value that will be seen on the screen
        var obj = {}; //obj used to encapsulate the object for outputting with selects 'defaultValue' prop

        if (value === "") {
            //nothing was selected output on screen
            obj = null;
        } else if (value && selected.length > 0) {
            //all previously saved, load into and object 'obj' to be outputted in selects 'defaultValue' prop
            if (display_label) {
                obj = selected.map((val) => {
                    if (val) {
                        let temp = {};
                        temp.value = val;
                        temp.label = options.find(o => o.value === parseInt(val)).label;
                        temp.color = "#3b7dd4";
                        return temp;
                    }
                    return null;
                });
            } else {
                obj = selected.map((val) => {
                    if (val) {
                        let temp = {};
                        temp.value = val;
                        temp.label = val;
                        temp.color = "#3b7dd4";
                        return temp;
                    }
                    return null;
                });
            }
        } else if (value && selected.length === 0) {
            //set the value to show on UI even though isnt in list
            obj.value = value;
            obj.label = value;
            obj.color = "#3b7dd4";
        } else {
            //needed for inital render but check other cases first
            obj = null;
        }

        //this is used for advancedSearch Field setups, it uses an 'id' and 'label' for each saved value to be rendered.
        var defaultVal = [];
        if (rest.defaultValue && !quickSearchHandle) {
            rest.defaultValue.forEach((v) => {
                var t = {};
                t.value = v.value;
                t.label = v.label;
                t.color = "#3b7dd4";
                defaultVal.push(t);
            });
        } else if (rest.defaultValue && quickSearchHandle) {
            rest.defaultValue.forEach((v) => {
                var t = {};
                t.value = v.value;
                t.label = v.label;
                t.color = "#3b7dd4";
                defaultVal.push(v);
            });
        }

        return (
            <div>
                {label && (
                    <Typography
                        style={{
                            /*whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",*/
                            marginTop: !repeatable ? -3 : 6,
                            marginBottom: dashboardHeight ? 3 : 0,
                        }}>
                        {label}
                    </Typography>
                )}

                <Select //default case for dropdown field setups, etc.
                    isMulti={rest.isMulti} //allow multiple
                    defaultValue={rest.defaultValue ? defaultVal : obj} //defaultValue is used for when not using form fields. ie when grabbing multiple fields ids
                    isDisabled={rest.disabled}
                    isClearable={rest.dontClear ? false : true}
                    isSearchable
                    //onBlur={setTouched}
                    onChange={this.handleChange} // call local handlechange for normal fields in records, for passing upwards use advancedDialog prop to true
                    name="dropdowns"
                    options={options} //value/label table
                    styles={hasError && obj === null ? colourStylesError : colourStyles} //calls object above for styling, any errors use diff object
                    menuIsOpen={rest.menuIsOpen}
                    menuPortalTarget={!rest.menuIsOpen ? document.body : false} //used for dropdown to not be under dialog window--on advanced search window doesnt defaultly open unless prop is false
                    size={dashboardHeight ? 36.5 : 32}
                />
                {rest.required && !hasError && <FormHelperText style={{ marginTop: -1 }}>Required</FormHelperText>}
                {rest.required && hasError && (
                    <input //use to allow required to come up on screen
                        tabIndex={-1}
                        value={obj === null ? "Required Field" : ""}
                        style={{
                            opacity: 1,
                            border: 0,
                            color: "#f44336",
                            fontSize: 11,
                            zIndex: 0,
                        }}
                        autoComplete="off"
                    />
                )}
            </div>
        );
    }
}
SelectWrapper.displayName = "SelectWrapper";
const ReactSelect = FormField(SelectWrapper);

export default ReactSelect;
